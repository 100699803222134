import Device from "device";
import styled, { css } from "styled-components";

import { ReactComponent as SvgTriangles } from "shared/assets/graphic-triangles.svg";
import { ReactComponent as SvgCheck } from "shared/assets/icons/icon-check.svg";

export const Section = styled.section`
  width: 100%;
  max-width: 100%;
  position: relative;
  padding: 128px 0 0 0;
  user-select: none;
  position: relative;

  @media ${Device.laptop} {
    padding: 160px 0 0 0;
  }
`;
export const Triangle = styled.div`
  display: none;

  @media ${Device.laptop} {
    display: block;
    position: absolute;
    background: linear-gradient(
      to bottom,
      #3282ff 0%,
      rgba(50, 130, 255, 0) 66.66%
    );
    opacity: 0.5;
    transform: rotate(225deg);
    width: 640px;
    height: 640px;
    bottom: -300px;
    left: -320px;
    z-index: -1;
    pointer-events: none;
  }
`;
export const Triangle2 = styled(SvgTriangles)`
  display: none;

  @media ${Device.laptop} {
    position: absolute;
    width: 320px;
    height: 960px;
    bottom: -460px;
    right: 0;
    z-index: -1;
    pointer-events: none;
  }
`;
export const Title = styled.h2`
  text-align: left;
`;
export const Items = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  grid-auto-rows: auto;
  width: 100%;
  margin: 0 0 40px 0;

  --stroke-color: var(--accent-secondary);
  --stroke-opacity: 1;

  @media ${Device.laptop} {
    grid-template-columns: repeat(4, 1fr);
    margin: 0 0 80px 0;
  }
`;
export const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 30px 0;
  padding: 0 20px 0 0;

  @media ${Device.laptop} {
    margin: 0 0 40px 0;
    padding: 0 40px 0 0;
  }
`;
export const ItemText = styled.div`
  font-size: 18px;
  margin: 18px 0 0 0;
`;
const IconCss = css`
  width: 24px;
  height: 24px;
`;
export const IconCheck = styled(SvgCheck)`
  ${IconCss}
`;
export const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 40px 0 0 0;
`;
export const Exchanges = css`
  width: 100%;
  height: 80px;
  margin: 0 0 40px 0;
  background-position: 0px 50%;
  background-size: 1388px 80px;
  background-repeat: repeat-x;
  transition: all 0.05s;
`;
export const ExchangesTop = styled.div`
  ${Exchanges}
  background-image: url('/i/exchanges-1.svg');
  background-size: 1120px 80px;
`;
export const ExchangesBottom = styled.div`
  ${Exchanges}
  background-image: url('/i/exchanges-2.svg');
  background-size: 1130px 80px;
`;
