import { Container } from "shared/components/Container";
import * as S from "./styled";

export function Footer() {
  const handleScrollTop = () => {
    let el = document.getElementById("root");
    el && el.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <S.Wrap>
      <Container>
        <S.Nav>
          <S.Copy>
            &copy;&nbsp;
            <S.CopyLink href="https://exan.tech/" target="_blank">
              Exantech
            </S.CopyLink>
            &nbsp;2024
          </S.Copy>
          <S.NavItem to="https://pro.multik.app/privacy_policy.html" target="_blank">
            Privacy Policy
          </S.NavItem>
          <S.NavItem to="https://multik.io/" target="_blank">
            User Agreement
          </S.NavItem>
          <S.NavItem to="https://helpdesk.multik.io/" target="_blank">
            Help Center
          </S.NavItem>
          <S.ToTop onClick={() => handleScrollTop()}>
            <S.IconTop />
          </S.ToTop>
        </S.Nav>
      </Container>
    </S.Wrap>
  );
}
