import Device from "device";
import { ReactComponent as SvgLock } from "shared/assets/icons/icon-lock.svg";
import styled from "styled-components";

export const Section = styled.section`
  width: 100%;
  max-width: 100%;
  position: relative;
  padding: 128px 0 0 0;
  user-select: none;
  position: relative;

  @media ${Device.laptop} {
    padding: 160px 0 0 0;
  }
`;
export const Title = styled.h2`
  text-align: center;
`;
export const SubTitle = styled.h3`
  text-align: left;
  font-size: 150%;
`

export const Wrap = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;
export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  grid-auto-rows: auto;
  width: 100%;
  margin: 40px 0 40px 0;
  position: relative;
  flex: 1;
`;

export const ListItem = styled.div``;
export const ListItemText = styled.div`
  margin: 16px 0 0 0;
`;
export const IconLock = styled(SvgLock)`
  width: 24px;
  height: 24px;
`;
export const Pic = styled.picture`
  display: none;

  img {
    width: 100%;
  }

  @media ${Device.laptop} {
    display: block;
    width: 100%;
    padding-top: 1rem;
  }
`;
export const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 40px 0 0 0;
`;


export const TLine = styled.div`
  width: 100%;
  display: flex;
  column-gap: 10%
`;

