import { useContext } from "react";
import { Button } from "shared/components/Button";
import { Container } from "shared/components/Container";
import HomeContext from "shared/contexts/HomeContext";
import * as S from "./styled";

const Keys = () => {
  const { handleScrollTo } = useContext(HomeContext);
  return (
    <S.Wrap id="keys">
      <Container>
        <S.Title>Your keys, your money<br/>
          <S.Subtitle>You don't need a custodian. You're already the custodian.</S.Subtitle>
        </S.Title>
        <S.Transfer>
          <S.TransferColumn>
            <S.TransferItem>Request</S.TransferItem>
          </S.TransferColumn>
          <S.TransferColumnBorder>
            <S.TransferTitle>Secure Transfer Environment</S.TransferTitle>
            <S.TransferItem>SGX Enclave</S.TransferItem>
            <S.TransferItem>Policy Engine</S.TransferItem>
            <S.ArrowIn />
            <S.ArrowOutTop />
            <S.ArrowOut />
            <S.ArrowOutBottom />
          </S.TransferColumnBorder>
          <S.TransferColumnLast>
            <S.TransferItem>Exchange</S.TransferItem>
            <S.TransferItem>Broker</S.TransferItem>
            <S.TransferItem>DeFi Protocol</S.TransferItem>
          </S.TransferColumnLast>
          <S.Key>
            <img
              srcSet="/i/key.png, /i/key@2x.png 2x"
              src="/i/key.png"
              alt="Your keys, your money"
            />
          </S.Key>
        </S.Transfer>
        <S.List>
          <S.ListItem>
            <S.ListPic>
              <img
                srcSet="/i/icons/connect.png, /i/icons/connect@2x.png 2x"
                alt=""
              />
            </S.ListPic>
            <S.ListItemText>
              Connect your existing exchange accounts for improved liquidity and
              portfolio management
            </S.ListItemText>
          </S.ListItem>
          <S.ListItem>
            <S.ListPic>
              <img
                srcSet="/i/icons/security.png, /i/icons/security@2x.png 2x"
                alt=""
              />
            </S.ListPic>
            <S.ListItemText>
              API keys are securely stored in an environment backed by Intel® SGX
              enclaves inside secured network
            </S.ListItemText>
          </S.ListItem>
          <S.ListItem>
            <S.ListPic>
              <img srcSet="/i/icons/key.png, /i/icons/key@2x.png 2x" alt="" />
            </S.ListPic>
            <S.ListItemText>
              Multik will never have access to any of your keys or private data
            </S.ListItemText>
          </S.ListItem>
          <S.ListItem>
            <S.ListPic>
              <img srcSet="/i/icons/mpc.png, /i/icons/mpc@2x.png 2x" alt="" />
            </S.ListPic>
            <S.ListItemText>
              Onchain cold wallets and policy engine enables you to operate funds securely within flexible policies
            </S.ListItemText>
          </S.ListItem>
        </S.List>
        <S.ButtonWrap>
          <Button onClick={() => handleScrollTo("getstarted")}>
            Get started
          </Button>
        </S.ButtonWrap>
      </Container>
      <S.Triangle />
    </S.Wrap>
  );
};

export default Keys;
