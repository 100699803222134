import Device from "device";
import styled from "styled-components";


export const Section = styled.section`
  width: 100%;
  max-width: 100%;
  position: relative;
  padding: 128px 0 0 0;
  user-select: none;
  position: relative;

  @media ${Device.laptop} {
    padding: 160px 0 0 0;
  }
`;
export const Title = styled.h2`
  text-align: center;
  margin-bottom: 40px;
`;

export const Wrap = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  column-gap: 3rem;
`

export const Tab = styled.h3`
  font-size: 110%;
  color: #80899D;
  cursor: pointer;
  padding-bottom: 4px;

  &.active {
    color: #ffffff;
    border-bottom: 2px solid #0064FF;
  }
`

export const Blocks = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  column-gap: 3rem;
  row-gap: 3rem;
  justify-content: space-evenly;
  line-height: 1.75rem;
`

export const Block = styled.div`
  min-width: 28%;
  width: 28%;
`

export const SubTitle = styled.h4`
  text-transform: uppercase;
`

export const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 40px 0 0 0;
`;
