import { useContext } from "react";
import { Button } from "shared/components/Button";
import { Container } from "shared/components/Container";
import HomeContext from "shared/contexts/HomeContext";
import * as S from "./styled";

const Technology = () => {
  const { handleScrollTo } = useContext(HomeContext);
  return (
    <S.Section id="technology">
      <Container>
        <S.Title>Technology highlights</S.Title>
        <S.Wrap>
          <div style={{display: "flex", width: "100%", flexDirection: "column", rowGap: "4rem"}}>
            <S.TLine>
              <div style={{width: "50%", lineHeight: "1.75rem"}}>
                <S.SubTitle>Unlimited exchange accounts in one place</S.SubTitle>
                <p>Unlock the full potential of your crypto portfolio with <b>Multik</b>.</p>

<p>Connect an unlimited number of accounts from 10+ top tier cryptocurrency exchanges and view a consolidated summary of all your assets in one place.</p>

<p>With just a few clicks, effortlessly transfer funds between accounts, ensuring optimal management of your digital assets.</p>

<p>Experience unparalleled convenience and control over your crypto holdings with our unified asset management solution.</p>
              </div>
              <div style={{width: "50%"}}>
                <S.Pic>
                  <img
                    src="/i/technology-overview.png"
                    alt=""
                  />
                </S.Pic>
              </div>
            </S.TLine>

            <S.TLine>
              <div style={{width: "50%", lineHeight: "1.75rem"}}>
                <S.Pic style={{textAlign: "center"}}>
                  <img
                    style={{width: "70%"}}
                    src="/i/technology-enclave.png"
                    alt=""
                  />
                </S.Pic>
              </div>
              <div style={{width: "50%"}}>
                <S.SubTitle>Secure Intel® SGX Enclave technology</S.SubTitle>
                <p>The <b>Multik</b> platform leverages Intel SGX technology for top-tier data security.</p>
                <p>SGX enclaves are isolated memory areas, protected from unauthorized access, even by the OS. Not even Multik developers can access the data within.</p>
                <p>All withdrawal requests are securely signed within the Enclave, ensuring only authorized actions are executed, eliminating forgery risks.</p>
                <p>The Provisioning App for admin connects to the Enclave via a secure API, ensuring safe storage and processing of confidential information.</p>
              </div>
            </S.TLine>

            <S.TLine>
              <div style={{width: "50%", lineHeight: "1.75rem"}}>
                <S.SubTitle>Flexible Policy Engine</S.SubTitle>
                <p><b>Multik</b> features a flexible Policy Engine that allows you to customize user and account limits,
                  thresholds, and account pools, giving you full control over fund management.</p>

<p>Each transaction may require a quorum of votes from other workspace users, with customizable voting thresholds, adding an extra layer of protection. Transfers are only
  possible to whitelisted addresses, ensuring secure transactions by allowing only pre-approved destinations.</p>

<p>These flexible transfer settings create a strong second layer of security on <b>Multik</b> platform.</p>
              </div>
              <div style={{width: "50%"}}>
                <S.Pic>
                  <img
                    src="/i/technology-limits.png"
                    alt=""
                  />
                </S.Pic>
              </div>
            </S.TLine>


            <S.TLine>
              <div style={{width: "50%", lineHeight: "1.75rem"}}>
                <S.Pic style={{textAlign: "center", paddingTop: 0}}>
                  <img
                    style={{width: "70%"}}
                    src="/i/technology-analytics.png"
                    alt=""
                  />
                </S.Pic>
              </div>
              <div style={{width: "50%"}}>
                <S.SubTitle>Analytics and reporting</S.SubTitle>
                <p>The <b>Multik</b> platform offers user-friendly analytics and detailed reporting on transactions and trading positions.</p>
<p>Easily export reports to keep track of your financial activities and make informed decisions.</p>
<p>With <b>Multik</b>, you have all the tools you need for comprehensive financial oversight at your fingertips.</p>
              </div>
            </S.TLine>

          </div>

        </S.Wrap>
        <S.ButtonWrap>
          <Button onClick={() => handleScrollTo("getstarted")}>
            Get started
          </Button>
        </S.ButtonWrap>
      </Container>
    </S.Section>
  );
};

export default Technology;
