import { useContext, useState } from "react";
import { Button } from "shared/components/Button";
import { Container } from "shared/components/Container";
import HomeContext from "shared/contexts/HomeContext";
import * as S from "./styled";

const Customers = () => {
  const { handleScrollTo } = useContext(HomeContext);
  const [activeTab, setActiveTab] = useState(0);

  const blocks = {
    'unified-mgmt': <S.Block>
            <S.SubTitle>Unified Asset Management</S.SubTitle>
            <p>Manage all your assets from wallets, safes, and exchange accounts on one screen
            with a unified interface, reducing errors and boosting transparency. Effortlessly monitor performance,
            make informed decisions, and consolidate holdings for a streamlined experience.</p>
          </S.Block>,
    'policies': <S.Block>
            <S.SubTitle>Enhanced Security Policies</S.SubTitle>
            <p>Secure transactions with our flexible Policy Engine featuring addressbook whitelisting, thresholds, payment limits, and multi-signature schemes. Multi-signature adds extra security by requiring multiple approvals. Ensure only authorized transactions, reducing fraud risk.</p>
          </S.Block>,
    'reporting': <S.Block>
            <S.SubTitle>Transaction Reporting</S.SubTitle>
            <p>Easily reconcile transactions and export for accounting. Enhance transparency with read-only access to transaction history for audits and analysis.</p>
          </S.Block>,
    'cold-storage': <S.Block>
            <S.SubTitle>Cold Storage Solutions</S.SubTitle>
            <p>Secure your main company funds with a cold storage ERC-20 vault. Keep assets offline and safe from cyber threats. Use industry-leading solutions to ensure maximum security.</p>
          </S.Block>,
    'fiat': <S.Block>
            <S.SubTitle>Seamless Fiat Integration</S.SubTitle>
            <p>Quickly convert between fiat (EUR) and crypto (USDC) without third-party services. Enjoy seamless transactions and enhanced liquidity for your investments.</p>
          </S.Block>,
    'api': <S.Block>
            <S.SubTitle>Automated Trading API</S.SubTitle>
            <p>Automate trading with secure REST API rails to reduce manual work and boost efficiency. Reliable APIs ensure stable, secure trade execution, optimizing operations with advanced automation tools.</p>
          </S.Block>,
    'portfolio': <S.Block>
            <S.SubTitle>REAL-TiME Portfolio overview </S.SubTitle>
            <p>Get a comprehensive, real-time view of your entire portfolio, enabling you to make informed decisions quickly and efficiently. Monitor performance metrics and trends to stay ahead of the market with instant updates and detailed analytics</p>
          </S.Block>,
    'liquidity': <S.Block>
            <S.SubTitle>Liquidity management</S.SubTitle>
            <p>Optimize liquidity management by integrating connected accounts under strict policies. Use address book whitelisting, thresholds, and payment limits for control. Enhance security with multi-signature schemes, requiring multiple approvals to minimize fraud risk and ensure authorized activities.</p>
          </S.Block>,
    'audit': <S.Block>
            <S.SubTitle>Access for auditors</S.SubTitle>
            <p>Read-only access for external auditors to portfolio and reporting ensures transparency and accountability in your financial operations. This feature allows auditors to review all necessary data without the risk of accidental modifications. </p>
          </S.Block>
  }

  const maps = {
    0: ["unified-mgmt", "policies", "reporting", "cold-storage", "fiat", "api"],
    1: ["portfolio", "reporting", "fiat", "api"],
    2: ["portfolio", "liquidity", "audit", "fiat", "api"],
    3: ["policies", "portfolio", "audit", "fiat"]
  }

  return (
    <S.Section id="customers">
      <Container>
        <S.Title>Adaptable for every strategy</S.Title>
        <S.Wrap>
          <S.Tabs>
            <S.Tab onClick={() => setActiveTab(0)} className={activeTab === 0 ? "active": ""}>Dealing with crypto</S.Tab>
            <S.Tab onClick={() => setActiveTab(1)} className={activeTab === 1 ? "active": ""}>Trading firm & market-maker</S.Tab>
            <S.Tab onClick={() => setActiveTab(2)} className={activeTab === 2 ? "active": ""}>Hedge fund</S.Tab>
            <S.Tab onClick={() => setActiveTab(3)} className={activeTab === 3 ? "active": ""}>Family office</S.Tab>
          </S.Tabs>
        </S.Wrap>

        <S.Blocks>
          {maps[activeTab].map((b) => blocks[b])}
        </S.Blocks>

        <S.ButtonWrap>
          <Button onClick={() => handleScrollTo("getstarted")}>
            Get started
          </Button>
        </S.ButtonWrap>
      </Container>
    </S.Section>
  );
}

export default Customers;