import { useContext, useState } from "react";
import { Container } from "shared/components/Container";
import HomeContext from "shared/contexts/HomeContext";
import * as S from "./styled";
// import { Button } from "shared/components/Button";

const FAQ = () => {
  const { handleScrollTo } = useContext(HomeContext);
  const [isActivePanel, setActivePanel] = useState({});
  const toggleItem = index => {
    setActivePanel(prevState => ({...prevState, [index]: !Boolean(prevState[index])}));
  };

  const faq = [
    ["How do you store your access credentials to accounts?",
     "You'll never need to look for login/password or even private keys to perform a transaction. With the Multik provisioning app, you add your exchange accounts by a simple tutorial and save their API keys to your Multik secure storage. Inside Multik you operate only with simple readable aliases addressing specific exchanges/wallets/accounts."],
    ["How do you store your access credentials to accounts?",
     "You'll never need to look for login/password or even private keys to perform a transaction. With the Multik provisioning app, you add your exchange accounts by a simple tutorial and save their API keys to your Multik secure storage. Inside Multik you operate only with simple readable aliases addressing specific exchanges/wallets/accounts."],
    ["How do you store your access credentials to accounts?",
     "You'll never need to look for login/password or even private keys to perform a transaction. With the Multik provisioning app, you add your exchange accounts by a simple tutorial and save their API keys to your Multik secure storage. Inside Multik you operate only with simple readable aliases addressing specific exchanges/wallets/accounts."],
    ["How do you store your access credentials to accounts?",
     "You'll never need to look for login/password or even private keys to perform a transaction. With the Multik provisioning app, you add your exchange accounts by a simple tutorial and save their API keys to your Multik secure storage. Inside Multik you operate only with simple readable aliases addressing specific exchanges/wallets/accounts."],
  ]

  return (
    <S.Section id="faq">
      <Container>
        <S.Title>Frequently Asked Questions</S.Title>
        <S.Wrap>
          {faq.map((item, index) => {
            return <S.Block>
              <S.Q className={isActivePanel[index] ? "opened": ""} onClick={() => toggleItem(index)}>
                {item[0]}
              </S.Q>
              <S.A className={isActivePanel[index] ? "opened": ""}>{item[1]}</S.A>
            </S.Block>
          })}
        </S.Wrap>

         {/* <S.ButtonWrap>
          <Button onClick={() => handleScrollTo("getstarted")}>
            Get started
          </Button>
        </S.ButtonWrap> */}
      </Container>
    </S.Section>
  );
}

export default FAQ;
